import React, { useEffect } from 'react';
import './aboutUs.css';
import Brands from '../../Components/Brands/brands';
import Services from '../../Components/Services/services';
import { scrollToTop } from '../../functions/scrollToTop';

const AboutUS = () => {
    useEffect(()=>{
        scrollToTop();
    },[])
    return (
        <main>
            <section className='single_equipment py-lg-5 py-4'>
                <div className="container">
                    <div className='navigation_border mb-lg-4 mb-3 aos-init aos-animate'>
                        <div className="row">
                            <div className='col-md-12 text-md-right text-center pb-lg-3 d-none d-sm-block'>
                                <ul className='sort_breadcrums'>
                                    <li><a href="/">Home</a></li>
                                    <li>About US</li>
                                </ul>

                            </div>
                        </div>

                    </div>
                    <div className='content_area'>
                        <div className='img_border mb-4 text-center aos-init aos-animate'>
                            <img src="./images/about us banner.jpg" alt="About" className=" lazyloaded img-banner" />

                        </div>
                        <h3 className='mini_border_title pt-2 aos-init aos-animate'>
                        About GCES
                        </h3>
                        <p className='aos-init aos-animate'>since 2012, GCES has been one of the leading wholesale heavy machinery companies in the US with capability of providing equipment Sale, attachments value added services to our customers. Whether you’re a large company, small business, subcontractor, or a homeowner-builder, GCES has all the machines and the operators to meet all your earthmoving and heavy equipment needs.</p>
                        <p className='aos-init aos-animate'>We pride ourselves in providing our customers with reliable equipment teamed with experienced operators. We place a high emphasis on all machinery being in excellent order, which complies with the toughest safety standards. A fully equipped workshop ensures these high standards are maintained.</p>
                        {/* <h3 className='mini_border_title pt-2 aos-init aos-animate'>
                        Who are GCES ?
                        </h3>
                        <p className='aos-init aos-animate'>friendly informative staff and experienced professionals will look after all your earthmoving requirements. We consistently deliver high levels of customer service during all aspects of the selling process, from quotation through to job completion and we are available 24 hours a day, 7 days a week.</p>
                         */}
                        <h3 className='mini_border_title pt-2 aos-init aos-animate'>
                        OUR PRODUCTS
                        </h3>
                        <p className='aos-init aos-animate'>
                        We primarily deal with the sale and supply of used heavy machinery. This includes an extensive range of construction equipment including:
                        </p>
                        <ul className="aos-init aos-animate" data-aos="fade-up">
                            <li>Backhoe</li>
                            <li>Excavator</li>
                            <li>Mini Excavator </li>
                            <li>Wheel loader</li>
                            <li>Track loader</li>
                            <li>Dozer</li>
                            <li>Motor Grader</li>
                            <li>Skid Steer</li>
                            <li>Telehandler</li>
                            <li>Roller</li>
                            <li>Dump Truck </li>
                        </ul>
                        <h3 className='mini_border_title pt-2 aos-init aos-animate'>
                        OUR BRANDS
                        </h3>
                        <p className='aos-init aos-animate'>
                        We primarily deal with the sale and supply of used heavy machinery. This includes an extensive range of construction equipment including:
                        </p>
                        <ul className="aos-init aos-animate" data-aos="fade-up">
                            <li>Caterpillar </li>
                            <li>CASE</li>
                            <li>John Deere</li>
                            <li>Hitachi</li>
                            <li>Volvo</li>
                            <li>Doosan</li>
                            <li>Komatsu</li>
                        </ul>
                        <p className='aos-init aos-animate'>
                        We stock the finest machinery from the world's leading brands. If we don't have what you're looking for in stock right now, we will do our best to find it for you, Request specific makes and models or speak to the team who can always suggest alternatives and help you get the right equipment for the job at hand.
                        </p>

                    </div>
                </div>

            </section>

             {/* Our Service  */}
             <Services />

            {/* Our Brands */}
            {/* <section className='brands_logos py-5'>
                <div className='container aos-init aos-animate'>
                    <h3 className='border_title txt_dark'>
                        Our Brands
                    </h3>
                    <ul className="mt-3 ml-lg-5 list-inline">
                        <li><a href="/caterpillar">
                            <img src="https://www.my-equipment.com/newsite/images/brands/icons/caterpillar.png?v=240128103245" data-src="https://www.my-equipment.com/newsite/images/brands/icons/caterpillar.png?v=240128103245" alt="Caterpillar" width={107} height={53} className=" lazyloaded" /></a>
                        </li>
                        <li><a href="/dynapac">
                            <img src="https://www.my-equipment.com/newsite/images/brands/icons/dynapac.png?v=240128103245" data-src="https://www.my-equipment.com/newsite/images/brands/icons/dynapac.png?v=240128103245" alt="Dynapac" width={107} height={53} className=" lazyloaded" /></a>
                        </li>
                        <li><a href="/tadano">
                            <img src="https://www.my-equipment.com/newsite/images/brands/icons/tadano.png?v=240128103245" data-src="https://www.my-equipment.com/newsite/images/brands/icons/tadano.png?v=240128103245" alt="Tadano" width={107} height={53} className=" lazyloaded" /></a>
                        </li>
                        <li><a href="/grove">
                            <img src="https://www.my-equipment.com/newsite/images/brands/icons/grove.png?v=240128103245" data-src="https://www.my-equipment.com/newsite/images/brands/icons/grove.png?v=240128103245" alt="Grove" width={107} height={53} className=" lazyloaded" /></a>
                        </li>
                        <li><a href="/hitachi">
                            <img src="https://www.my-equipment.com/newsite/images/brands/icons/hitachi.png?v=240128103245" data-src="https://www.my-equipment.com/newsite/images/brands/icons/hitachi.png?v=240128103245" alt="Hitachi" width={107} height={53} className=" lazyloaded" /></a>
                        </li>
                        <li><a href="/volvo">
                            <img src="https://www.my-equipment.com/newsite/images/brands/icons/volvo.png?v=240128103245" data-src="https://www.my-equipment.com/newsite/images/brands/icons/volvo.png?v=240128103245" alt="Volvo" width={107} height={53} className=" lazyloaded" /></a>
                        </li>
                        <li><a href="/hyundai">
                            <img src="https://www.my-equipment.com/newsite/images/brands/icons/hyundai.png?v=240128103245" data-src="https://www.my-equipment.com/newsite/images/brands/icons/hyundai.png?v=240128103245" alt="Hyundai" width={107} height={53} className=" lazyloaded" /></a>
                        </li>
                        <li><a href="/komatsu">
                            <img src="https://www.my-equipment.com/newsite/images/brands/icons/komatsu.png?v=240128103245" data-src="https://www.my-equipment.com/newsite/images/brands/icons/komatsu.png?v=240128103245" alt="Komatsu" width={107} height={53} className=" lazyloaded" /></a>
                        </li>
                        <li><a href="/champion">
                            <img src="https://www.my-equipment.com/newsite/images/brands/icons/champion.png?v=240128103245" data-src="https://www.my-equipment.com/newsite/images/brands/icons/champion.png?v=240128103245" alt="Champion" width={107} height={53} className=" lazyloaded" /></a>
                        </li>
                        <li><a href="/ingersoll-rand">
                            <img src="https://www.my-equipment.com/newsite/images/brands/icons/ingersoll-rand.png?v=240128103245" data-src="https://www.my-equipment.com/newsite/images/brands/icons/ingersoll-rand.png?v=240128103245" alt="Ingersoll Rand" width={107} height={53} className=" lazyloaded" /></a>
                        </li>
                        <li><a href="/massey-ferguson">
                            <img src="https://www.my-equipment.com/newsite/images/brands/icons/massey-ferguson.png?v=240128103245" data-src="https://www.my-equipment.com/newsite/images/brands/icons/massey-ferguson.png?v=240128103245" alt="Massey Ferguson" width={107} height={53} className=" lazyloaded" /></a>
                        </li>
                        <li><a href="/case">
                            <img src="https://www.my-equipment.com/newsite/images/brands/icons/case.png?v=240128103245" data-src="https://www.my-equipment.com/newsite/images/brands/icons/case.png?v=240128103245" alt="Case" width={107} height={53} className=" lazyloaded" /></a>
                        </li>
                        <li><a href="/my-equipment">
                            <img src="https://www.my-equipment.com/newsite/images/brands/icons/my-equipment.png?v=240128103245" data-src="https://www.my-equipment.com/newsite/images/brands/icons/my-equipment.png?v=240128103245" alt="MY Equipment" width={107} height={53} className=" lazyloaded" /></a>
                        </li>
                        <li><a href="/john-deere">
                            <img src="https://www.my-equipment.com/newsite/images/brands/icons/john-deere.png?v=240128103245" data-src="https://www.my-equipment.com/newsite/images/brands/icons/john-deere.png?v=240128103245" alt="John Deere" width={107} height={53} className=" lazyloaded" /></a>
                        </li>
                        <li><a href="/Alliance">
                            <img src="https://www.my-equipment.com/newsite/images/brands/icons/Alliance.png?v=240128103245" data-src="https://www.my-equipment.com/newsite/images/brands/icons/Alliance.png?v=240128103245" alt="Alliance" width={107} height={53} className=" lazyloaded" /></a>
                        </li>
                        <li><a href="/michelin">
                            <img src="https://www.my-equipment.com/newsite/images/brands/icons/michelin.png?v=240128103245" data-src="https://www.my-equipment.com/newsite/images/brands/icons/michelin.png?v=240128103245" alt="Michelin" width={107} height={53} className=" lazyloaded" /></a>
                        </li>
                    </ul>

                </div>

            </section> */}
            <Brands />
            
        </main>
    );
}

export default AboutUS;
