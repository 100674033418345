import axios from "axios";
import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { NavLink, useNavigate } from "react-router-dom";
import ListCategoriesBrandsSlice from "../../store/action/listCategories-BrandsSlice";
import "./headerWithoutBg.css";
import ListProductsSlice from "../../store/action/listProductsSlice";
import StockList from "../../store/action/stockList";
import Spinner from "../Spinner/spinner";

const HeaderWithoutBg = () => {
  const navigate = useNavigate();
  const dispatch = useDispatch();

  const categoriesBrands = useSelector((state) => state.categoriesBrands);
  const stockList = useSelector((state) => state.stockList);
  const productsList = useSelector((state) => state.productsList);
  const [isLoading, setIsLoading] = useState(false);

  // function to handle search
  const hangelSearch = (e) => {
    e.preventDefault();
    let result = window.document.querySelector("#project-id").value;
    navigate(`/mega-search?result=${result}`);
    // console.log(result);
  };

  // get all categories and Brands when hover of category
  const gelAllBrandsAndCat = async () => {
    if (!categoriesBrands) {
      const res = await axios.get(
        `${process.env.REACT_APP_SERVER_URL}/products/categories-brands`
      );
      dispatch(ListCategoriesBrandsSlice(res.data));
      // console.log(res.data);
    } else {
      console.log("dont have categoriesBrands");
    }
  };

  // const getProducts = async () => {
  //     if (!productsList) {
  //         const res = await axios.get(`${process.env.REACT_APP_SERVER_URL}/products?limit=20&page=0&search=&category=&brand=&model&year=&sold=false`);
  //         dispatch(ListProductsSlice(res.data));
  //         console.log(res.data);
  //     }
  // }

  const getStockList = async () => {
    if (!stockList) {
      const res = await axios.get(
        `${process.env.REACT_APP_SERVER_URL}/products/stock`
      );
      dispatch(StockList(res.data));
      // console.log(res.data);
    }
  };

  // function to get all products as pdf
  const getPPF = async () => {
    setIsLoading(true);
    try {
      const response = await axios.get(
        `${process.env.REACT_APP_SERVER_URL}/products/stockPdf`,
        {
          responseType: 'arraybuffer'
        }
      );
      const pdfBlob = new Blob([response.data], { type: 'application/pdf' });

      const pdfUrl = URL.createObjectURL(pdfBlob);
      window.open(pdfUrl);
      setIsLoading(false);
    } catch (error) {
      console.error('Error fetching PDF:', error);
    }
  };


  useEffect(() => {
    // getProducts();
    getStockList();
    gelAllBrandsAndCat();
    window.onscroll = function () {
      const ele = document.querySelector(".navbar-expand-lg");
      const eleTwo = document.querySelector(".topbar");
      if (window.scrollY >= 50) {
        ele.classList.add("fixed_menu");
        eleTwo.style = "display:none";
      } else {
        ele.classList.remove("fixed_menu");
        eleTwo.style = "";
      }
    };
  }, [dispatch]);

  return (
    <header className="" style={{ backgroundColor: "#4d4d4d" }}>
      {/* first section */}
      <div className="topbar d-md-flex align-items-center">
        <div className="container">
          <div className="pull-left">
            <form
              autoComplete="off"
              className="topbar_form_search"
              onSubmit={hangelSearch}
            >
              <div className="form_row position-relative">
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  width={16}
                  height={16}
                  fill="currentColor"
                  className="icon_search bi bi-search"
                  viewBox="0 0 16 16"
                >
                  <path d="M11.742 10.344a6.5 6.5 0 1 0-1.397 1.398h-.001q.044.06.098.115l3.85 3.85a1 1 0 0 0 1.415-1.414l-3.85-3.85a1 1 0 0 0-.115-.1zM12 6.5a5.5 5.5 0 1 1-11 0 5.5 5.5 0 0 1 11 0" />
                </svg>

                <input
                  type="text"
                  name="search"
                  id="project-id"
                  placeholder="What are you looking for?"
                  required="required"
                />

                {/* <svg xmlns="http://www.w3.org/2000/svg" width={16} height={16} fill="currentColor" className="bi bi-mic-fill microphone" viewBox="0 0 16 16">
                                    <path d="M5 3a3 3 0 0 1 6 0v5a3 3 0 0 1-6 0z" />
                                    <path d="M3.5 6.5A.5.5 0 0 1 4 7v1a4 4 0 0 0 8 0V7a.5.5 0 0 1 1 0v1a5 5 0 0 1-4.5 4.975V15h3a.5.5 0 0 1 0 1h-7a.5.5 0 0 1 0-1h3v-2.025A5 5 0 0 1 3 8V7a.5.5 0 0 1 .5-.5" />
                                </svg> */}
              </div>
            </form>
          </div>
          <div className="pull-right">
            <ul className="social_icons">
              <li>
                <a href="https://www.facebook.com/" target="_blank">
                  <i className="bi bi-facebook" />
                </a>
              </li>
              <li>
                <a href="https://www.linkedin.com/" target="_blank">
                  <i className="bi bi-linkedin" />
                </a>
              </li>
              <li>
                <a href="https://www.instagram.com/" target="_blank">
                  <i className="bi bi-instagram" />
                </a>
              </li>
              <li><a href="https://twitter.com/" target="_blank">
                <i className="bi bi-twitter-x" />
              </a>
              </li>

              {/* <li>
                                <a href="https://www.facebook.com/" target='_blank'>
                                    <i className="bi bi-pinterest" />
                                </a>
                            </li> */}
              <li>
                <a href="https://www.youtube.com/" target="_blank">
                  <i className="bi bi-youtube" />
                </a>
              </li>
            </ul>
            <a href="/contact-us" className="topbar_btn ">
              <i className="bi bi-chat-left-text main-color "></i>
              <span className=""> CONTACT</span>
            </a>
            <a href="" className="topbar_btn ">
              <i className="bi bi-pencil-fill main-color "></i>
              <span className=""> BLOG</span>
            </a>
            <a
              href="/subscribe-email"
              alt="subscribe"
              className="topbar_btn topbar_btn2 "
            >
              Subscribe
            </a>
          </div>
        </div>
      </div>

      {/* second section */}
      <nav className="navbar navbar-expand-lg c_navbar py-0">
        <div className="container">
          <a href="/">
            <svg width={185} height={84} xmlns="http://www.w3.org/2000/svg">
              <image href="/images/logo.png" width={190} height={100} />
            </svg>
          </a>
          <button
            className="navbar-toggler"
            type="button"
            data-bs-toggle="collapse"
            data-bs-target="#collapsibleNavbar"
            aria-controls="collapsibleNavbar"
            aria-label="Toggle navigation"
          >
            <i className="bi bi-list" />
          </button>
          <div className="navbar-collapse collapse" id="collapsibleNavbar">
            <div
              className="menu_overlay"
              onClick={() => {
                let toggel = document.querySelector(".navbar-collapse");
                toggel.classList.remove("show");
              }}
            ></div>
            <ul className="navbar-nav ml-auto">
              {/* Home */}
              {/* <li className='nav-item ' onMouseOver={(e) => {
                                document.getElementsByClassName('dropdown-brands')[0].classList.remove('show');
                                document.getElementsByClassName('dropdown-equipment')[0].classList.remove('show');
                                document.getElementsByClassName('dropdown-services')[0].classList.remove('show');
                                // console.log(document.getElementsByClassName('dropdown-brands')[0]);
                            }}>
                                <NavLink className={`nav-link  c_nav_link  ${({ isActive }) =>
                                    isActive ? "active" : ""} 
                                `} to="/">Home</NavLink>
             </li> */}

              {/* Stock */}
              <li
                className="nav-item  dropdown "
                onMouseOver={(e) => {
                  document
                    .getElementsByClassName("dropdown-equipment")[0]
                    .classList.add("show");
                  document
                    .getElementsByClassName("dropdown-services")[0]
                    .classList.remove("show");
                  document
                    .getElementsByClassName("dropdown-sold-item")[0]
                    .classList.remove("show");
                  document
                    .getElementsByClassName("dropdown-stock")[0]
                    .classList.remove("show");
                }}
              >
                <NavLink
                  className={`nav-link  c_nav_link  ${({ isActive }) =>
                    isActive ? "active" : ""}
                                `}
                  to="/stock-list"
                  name="equipment"
                  onClick={() => {
                    let toggel = document.querySelector(".navbar-collapse");
                    console.log(toggel.classList.remove("show"));
                  }}
                >
                  Stock
                </NavLink>
                <div
                  className="dropdown-menu dropdown-equipment"
                  aria-labelledby="navbarDropdown"
                  onMouseOut={(e) => {
                    document
                      .getElementsByClassName("dropdown-equipment")[0]
                      .classList.remove("show");
                    document
                      .getElementsByClassName("dropdown-cat")[0]
                      .classList.add("has-child-menu");
                  }}
                >
                  <div className="container pt-lg-3">
                    <div className="row">
                      <div className="col-lg-12 mx-50">
                        <ul className="top-bar-submenu-ul">
                          <div
                            className="dropdown-cat has-child-menu my-3 mx-2 "
                            onMouseOver={(e) => {
                              document
                                .getElementsByClassName("dropdown-cat")[0]
                                .classList.remove("has-child-menu");
                              document
                                .getElementsByClassName("dropdown-brand")[0]
                                .classList.add("has-child-menu");
                            }}
                          >
                            <a href="#">
                              Categories{" "}
                              <i className="bi bi-caret-down-fill " />
                            </a>

                            <div
                              className="child-menu"
                              onMouseOut={(e) => {
                                document
                                  .getElementsByClassName("dropdown-cat")[0]
                                  .classList.add("has-child-menu");
                              }}
                            >
                              <div className="row mb-lg-4 mx-8 my-5">
                                <div className="white-paper-mega-menu  equipment-dropdown card-container">
                                  {categoriesBrands &&
                                    categoriesBrands.categories.map(
                                      (cat, index) => {
                                        return (
                                          <div
                                            key={index}
                                            className="white-paper-menu-item m-2 cursor col-lg-4"
                                            onClick={() => {
                                              document
                                                .getElementsByClassName(
                                                  "dropdown-equipment"
                                                )[0]
                                                .classList.remove("show");
                                              document
                                                .getElementsByClassName(
                                                  "dropdown-cat"
                                                )[0]
                                                .classList.remove(
                                                  "has-child-menu"
                                                );
                                              document
                                                .getElementsByClassName(
                                                  "dropdown-brand"
                                                )[0]
                                                .classList.add(
                                                  "has-child-menu"
                                                );
                                              let result = cat.name
                                                .toLowerCase()
                                                .replace(/\s+/g, "-");
                                              navigate(`/${result}`);
                                            }}
                                          >
                                            <span className="an-machine-amount">
                                              {cat.stock}
                                            </span>
                                            <figure>
                                              <img
                                                src={`${process.env.REACT_APP_SERVER_URL}/${cat.image}`}
                                                alt={cat.name}
                                              />
                                            </figure>
                                            <h2>{cat.name}</h2>
                                            <a
                                              href={`${cat.name
                                                .toLowerCase()
                                                .replace(/\s+/g, "-")}`}
                                              className="hover"
                                            >
                                              <span>View Details</span>
                                            </a>
                                          </div>
                                        );
                                      }
                                    )}
                                </div>

                                {/* old design */}
                                {/* <div className="col-lg-2 mb-lg-3 px-8">
                                                                    <a href="/parts-attachments" className="single_megamenu">
                                                                        <img src="https://www.my-equipment.com/newsite/images/megamenu/equipment/parts-attachments.png" alt="Parts & Attachments" />
                                                                        <span>Parts &amp; Attachments</span>
                                                                        <div className="badge">11</div>
                                                                    </a>
                                                                </div> */}
                              </div>
                            </div>
                          </div>

                          <div
                            className="dropdown-brand has-child-menu"
                            onMouseOver={(e) => {
                              document
                                .getElementsByClassName("dropdown-brand")[0]
                                .classList.remove("has-child-menu");
                            }}
                          >
                            <a href="#">
                              Brands{" "}
                              <i className="bi bi-caret-down-fill " />
                            </a>

                            {/* hidden section */}
                            <div
                              className="child-menu"
                              onMouseOut={(e) => {
                                document
                                  .getElementsByClassName("dropdown-brand")[0]
                                  .classList.add("has-child-menu");
                              }}
                            >
                              <div className="row mb-lg-4 mx-8 my-5">
                                <div className="white-paper-mega-menu equipment-dropdown card-container">
                                  {categoriesBrands &&
                                    categoriesBrands.brands.map(
                                      (brand, index) => {
                                        return (
                                          <div
                                            key={index}
                                            className="white-paper-menu-item m-2 cursor"
                                            onClick={() => {
                                              let result = brand.name
                                                .toLowerCase()
                                                .replace(/\s+/g, "-");
                                              navigate(`/${result}`);
                                              // navigate(`/caterpillar`)
                                            }}
                                          >
                                            <span className="an-machine-amount">
                                              {brand.stock}
                                            </span>
                                            <figure>
                                              <img
                                                src={`${process.env.REACT_APP_SERVER_URL}/${brand.image}`}
                                                alt={brand.name}
                                              />
                                            </figure>
                                            <h2>{brand.name}</h2>
                                            <a
                                              href={`${brand.name
                                                .toLowerCase()
                                                .replace(/\s+/g, "-")}`}
                                              className="hover"
                                            >
                                              <span>View Details</span>
                                            </a>
                                          </div>
                                        );
                                      }
                                    )}
                                </div>
                                {/* old design */}
                                {/* <div className="col-lg-2 mb-lg-3 px-8">
                                                                    <a href="/brands" className="single_megamenu">
                                                                        <img src="https://www.my-equipment.com/newsite/images/megamenu/brands/michelin.png" alt="Michelin" />
                                                                        <span>Michelin</span>
                                                                        <div className="badge">1</div>
                                                                    </a>
                                                                </div> */}
                              </div>
                            </div>
                          </div>
                        </ul>
                      </div>
                    </div>
                  </div>
                </div>
              </li>

              {/* Services */}
              <li
                className="nav-item  dropdown"
                onMouseOver={(e) => {
                  document
                    .getElementsByClassName("dropdown-services")[0]
                    .classList.add("show");
                  document
                    .getElementsByClassName("dropdown-equipment")[0]
                    .classList.remove("show");
                  document
                    .getElementsByClassName("dropdown-sold-item")[0]
                    .classList.remove("show");
                  document
                    .getElementsByClassName("dropdown-stock")[0]
                    .classList.remove("show");
                  // document.getElementsByClassName('dropdown-brands')[0].classList.remove('show');
                  // console.log(document.getElementsByClassName('dropdown-services')[0]);
                }}
                onClick={() => {
                  let toggel = document.querySelector(".navbar-collapse");
                  console.log(toggel.classList.remove("show"));
                }}
              >
                <NavLink
                  className={`nav-link  c_nav_link  ${({ isActive }) =>
                    isActive ? "active" : ""} 
                                `}
                  to="/shipping"
                >
                  Services
                </NavLink>

                {/* Hidden Navbar section */}
                {/* <div className="dropdown-menu dropdown-services" aria-labelledby="navbarDropdown" onMouseOut={(e) => {
                                    document.getElementsByClassName('dropdown-services')[0].classList.remove('show');
                                }}>
                                    <div className="container pt-lg-3">
                                        <div className="row my-lg-4 mx-8">
                                            <div className="white-paper-menu-item m-2 cursor col-lg-3">
                                                <a href="/transport" className="services_mega_menu">
                                                    <img className='services-img' src="/images/transport2.jpg" alt="Transport" />
                                                    <span>Transport</span>
                                                </a>
                                            </div>
                                            <div className="col-lg-2 mb-lg-3 px-8">
                                                <a href="/transport" className="services_mega_menu">
                                                    <img  src="/images/transport.jpg" alt="Transport" />
                                                    <span>Transport</span>
                                                </a>
                                            </div>
                                            <div className="white-paper-menu-item m-2 cursor col-lg-3">
                                                <a href="/inspections" className="services_mega_menu">
                                                    <img className='services-img' src="/images/inspections2.jpg" alt="Inspection" />
                                                    <span>Inspection</span>
                                                </a>
                                            </div>
                                            <div className="white-paper-menu-item m-2 cursor col-lg-3">
                                                <a href="/shipping" className="services_mega_menu">
                                                    <img className='services-img' src="/images/shipping2.jpg" alt="Shipping" />
                                                    <span>Shipping</span>
                                                </a>
                                            </div>
                                        </div>
                                    </div>
                                </div> */}
                <div
                  className="dropdown-menu dropdown-services"
                  onMouseOut={(e) => {
                    document
                      .getElementsByClassName("dropdown-services")[0]
                      .classList.remove("show");
                  }}
                >
                  <div className="container pt-lg-3">
                    <div className="row justify-content-center white-paper-mega-menu">
                      <div className="white-paper-menu-item m-2 cursor col-lg-3 col-md-4 col-sm-6">
                        <a href="/transport" className="services_mega_menu">
                          <img
                            className="services-img"
                            src="/images/transport2.jpg"
                            alt="Transport"
                          />
                          <h2>Transport</h2>
                        </a>
                      </div>
                      <div className="white-paper-menu-item m-2 cursor col-lg-3 col-md-4 col-sm-6">
                        <a href="/inspections" className="services_mega_menu">
                          <img
                            className="services-img"
                            src="/images/inspections2.jpg"
                            alt="Inspection"
                          />
                          <h2>Inspection</h2>
                        </a>
                      </div>
                      <div className="white-paper-menu-item m-2 cursor col-lg-3 col-md-4 col-sm-6">
                        <a href="/shipping" className="services_mega_menu">
                          <img
                            className="services-img"
                            src="/images/shipping2.jpg"
                            alt="Shipping"
                          />
                          <h2>Shipping</h2>
                        </a>
                      </div>
                      <div className="white-paper-menu-item m-2 cursor col-lg-3 col-md-4 col-sm-6">
                        <a href="/new" className="services_mega_menu">
                          <img
                            className="services-img"
                            src="/images/inspections2.jpg"
                            alt="Inspection"
                          />
                          <h2>New Serivce</h2>
                        </a>
                      </div>
                    </div>
                  </div>
                </div>
              </li>

              {/*Parts */}
              <li
                className="nav-item "
                onMouseOver={(e) => {
                  document
                    .getElementsByClassName("dropdown-equipment")[0]
                    .classList.remove("show");
                  document
                    .getElementsByClassName("dropdown-services")[0]
                    .classList.remove("show");
                  document
                    .getElementsByClassName("dropdown-sold-item")[0]
                    .classList.remove("show");
                  document
                    .getElementsByClassName("dropdown-stock")[0]
                    .classList.remove("show");
                }}
              >
                <NavLink
                  className={`nav-link  c_nav_link  ${({ isActive }) =>
                    isActive ? "active" : ""}`}
                  to="/parts"
                  onClick={() => {
                    let toggel = document.querySelector(".navbar-collapse");
                    toggel.classList.remove("show");
                  }}
                >
                  Parts
                </NavLink>
              </li>

              {/*New Attachments */}
              <li
                className="nav-item "
                onMouseOver={(e) => {
                  // document.getElementsByClassName('dropdown-brands')[0].classList.remove('show');
                  document
                    .getElementsByClassName("dropdown-equipment")[0]
                    .classList.remove("show");
                  document
                    .getElementsByClassName("dropdown-services")[0]
                    .classList.remove("show");
                  document
                    .getElementsByClassName("dropdown-sold-item")[0]
                    .classList.remove("show");
                  document
                    .getElementsByClassName("dropdown-stock")[0]
                    .classList.remove("show");
                  // console.log(document.getElementsByClassName('dropdown-brands')[0]);
                }}
              >
                <NavLink
                  className={`nav-link  c_nav_link  ${({ isActive }) =>
                    isActive ? "active" : ""} 
                                `}
                  to="/attachments"
                  onClick={() => {
                    let toggel = document.querySelector(".navbar-collapse");
                    console.log(toggel.classList.remove("show"));
                  }}
                >
                  New Attachments
                </NavLink>
              </li>

              {/* Why Gces */}
              <li
                className="nav-item  dropdown "
                onMouseOver={(e) => {
                  document
                    .getElementsByClassName("dropdown-services")[0]
                    .classList.remove("show");
                  document
                    .getElementsByClassName("dropdown-sold-item")[0]
                    .classList.remove("show");
                  document
                    .getElementsByClassName("dropdown-stock")[0]
                    .classList.remove("show");
                }}
              >
                <NavLink
                  className={`nav-link  c_nav_link  ${({ isActive }) =>
                    isActive ? "active" : ""} 
                                `}
                  to="/why-gces"
                  onClick={() => {
                    let toggel = document.querySelector(".navbar-collapse");
                    console.log(toggel.classList.remove("show"));
                  }}
                >
                  Why Gces
                </NavLink>
              </li>

              {/* Contact US */}
              <li className="nav-item  dropdown  ">
                <NavLink
                  className={`nav-link  c_nav_link  ${({ isActive }) =>
                    isActive ? "active" : ""} 
                                `}
                  to="/contact-us"
                  onClick={() => {
                    let toggel = document.querySelector(".navbar-collapse");
                    console.log(toggel.classList.remove("show"));
                  }}
                >
                  Contact US
                </NavLink>
              </li>

              {/* About US */}
              <li
                className="nav-item  dropdown  "
                onMouseOver={(e) => {
                  document
                    .getElementsByClassName("dropdown-services")[0]
                    .classList.remove("show");
                  document
                    .getElementsByClassName("dropdown-sold-item")[0]
                    .classList.remove("show");
                  document
                    .getElementsByClassName("dropdown-stock")[0]
                    .classList.remove("show");
                }}
              >
                <NavLink
                  className={`nav-link  c_nav_link  ${({ isActive }) =>
                    isActive ? "active" : ""} 
                                `}
                  to="/about-us"
                  onClick={() => {
                    let toggel = document.querySelector(".navbar-collapse");
                    console.log(toggel.classList.remove("show"));
                  }}
                >
                  About Us
                </NavLink>
              </li>

              {/* Our Stock */}
              <li
                className="nav-item dropdown"
                onMouseOver={(e) => {
                  document
                    .getElementsByClassName("dropdown-sold-item")[0]
                    .classList.remove("show");
                  document
                    .getElementsByClassName("dropdown-stock")[0]
                    .classList.add("show");
                }}
              >
                <NavLink
                  to="stock-list"
                  className="nav-link  btn_navbar c_nav_link"
                >
                  <span>Our Stock</span>
                </NavLink>

                <div
                  className="dropdown-menu dropdown-stock"
                  aria-labelledby="navbarDropdown"
                  onMouseOut={(e) => {
                    document
                      .getElementsByClassName("dropdown-stock")[0]
                      .classList.remove("show");
                  }}
                >
                  <div className="container pt-lg-3">
                    <div className="row my-lg-4 mx-8">
                      <div className="col-lg-12">
                        <div className="our-stock-menus">
                          <div className="dropdown-content">
                            <div className="dropdown-heading">
                              <div>
                                <h3>Our Stock</h3>
                                <p>
                                  MY GCES deals with different kinds of
                                  equipment. See all below
                                </p>
                              </div>
                              <a
                                onClick={getPPF}
                                className="an-theme-btn outline black cursor"
                              >
                                <i className="bi bi-file-earmark-pdf fs-5"></i>{" "}
                                {!isLoading ? "Download Stock" : <Spinner />}
                                &nbsp;
                              </a>
                            </div>
                            {stockList &&
                              stockList.map((stock, index) => {
                                return (
                                  <div key={index}>
                                    <h4 className="dropdown-sub-heading ">
                                      {stock._id}
                                      <i className="bi bi-caret-right-fill"></i>
                                    </h4>
                                    <div className="equ-table-wrapper">
                                      <div className="equ-table-row">
                                        <div className="colum-1">
                                          <b>MACHINES</b>
                                        </div>
                                        <div className="colum-2">
                                          <b>PRICE</b>
                                        </div>
                                        <div className="colum-3">
                                          <b>SERIAL</b>
                                        </div>
                                        <div className="colum-4">
                                          <b>HOURS</b>
                                        </div>
                                        <div className="colum-5">
                                          <b>LOCATION</b>
                                        </div>
                                      </div>
                                      {stock.products.map((prod, index) => {
                                        return (
                                          <div
                                            key={index}
                                            className="equ-table-row"
                                          >
                                            <div className="colum-1">
                                              <a
                                                href={`/details?id=${prod._id}`}
                                              >
                                                {prod.name}
                                              </a>
                                            </div>
                                            <div className="colum-2">
                                              $ {prod.price}
                                            </div>
                                            <div className="colum-3">
                                              {prod.serial}
                                            </div>
                                            <div className="colum-4">
                                              {prod.hours}
                                            </div>
                                            <div className="colum-5">
                                              {prod.location}
                                            </div>
                                          </div>
                                        );
                                      })}
                                    </div>
                                  </div>
                                );
                              })}
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </li>

              {/* Sold Items */}
              <li
                className="nav-item dropdown"
                onMouseOver={(e) => {
                  document
                    .getElementsByClassName("dropdown-sold-item")[0]
                    .classList.add("show");
                  document
                    .getElementsByClassName("dropdown-stock")[0]
                    .classList.remove("show");
                  // gelAllBrandsAndCat();
                }}
              >
                <a
                  href="/sold-list"
                  className="nav-link  btn_navbar c_nav_link"
                >
                  <span>Sold Items</span>
                </a>

                {/* Hidden Navbar section */}
                <div
                  className="dropdown-menu dropdown-sold-item "
                  aria-labelledby="navbarDropdown"
                  onMouseOut={(e) => {
                    document
                      .getElementsByClassName("dropdown-sold-item")[0]
                      .classList.remove("show");
                  }}
                >
                  <div className="container">
                    <div className="row py-lg-4 mx-8">
                      <div className="white-paper-mega-menu  equipment-dropdown">
                        {categoriesBrands &&
                          categoriesBrands.categories.map((cat, index) => {
                            return (
                              <div
                                key={index}
                                className="white-paper-menu-item m-2 cursor col-4"
                              >
                                <span className="an-machine-amount">
                                  {cat.sold}
                                </span>
                                <figure>
                                  <img
                                    src={`${process.env.REACT_APP_SERVER_URL}/${cat.image}`}
                                    alt={cat.name}
                                  />
                                </figure>
                                <h2>{cat.name}</h2>
                                {/* <a href="/sold" className="hover">
                                                                <span>View Details</span>
                                                            </a> */}
                                <a
                                  href={`/sold/${cat.name
                                    .toLowerCase()
                                    .replace(/\s+/g, "-")}`}
                                  className="hover"
                                >
                                  <span>View Details</span>
                                </a>
                              </div>
                            );
                          })}
                      </div>
                      {/* <div className="col-lg-2 mb-lg-3 px-8">
                                                <a href="/sold" className="single_megamenu">
                                                    <img src="https://www.my-equipment.com/newsite/images/megamenu/equipment/motor-graders.png" alt="Motor Graders" />
                                                    <span>Motor Graders</span>
                                                    <div className="badge">836</div>
                                                </a>
                                            </div>
                                            <div className="col-lg-2 mb-lg-3 px-8">
                                                <a href="/sold" className="single_megamenu">
                                                    <img src="https://www.my-equipment.com/newsite/images/megamenu/equipment/track-excavators.png" alt="Track Excavators" />
                                                    <span>Track Excavators</span>
                                                    <div className="badge">133</div>
                                                </a>
                                            </div>
                                            <div className="col-lg-2 mb-lg-3 px-8">
                                                <a href="/sold" className="single_megamenu">
                                                    <img src="https://www.my-equipment.com/newsite/images/megamenu/equipment/wheel-excavators.png" alt="Wheel Excavators" />
                                                    <span>Wheel Excavators</span>
                                                    <div className="badge">15</div>
                                                </a>
                                            </div>
                                            <div className="col-lg-2 mb-lg-3 px-8">
                                                <a href="/sold" className="single_megamenu">
                                                    <img src="https://www.my-equipment.com/newsite/images/megamenu/equipment/crawler-dozers.png" alt="Crawler Dozers" />
                                                    <span>Crawler Dozers</span>
                                                    <div className="badge">139</div>
                                                </a>
                                            </div>
                                            <div className="col-lg-2 mb-lg-3 px-8">
                                                <a href="/sold" className="single_megamenu">
                                                    <img src="https://www.my-equipment.com/newsite/images/megamenu/equipment/backhoe-loaders.png" alt="Backhoe Loaders" />
                                                    <span>Backhoe Loaders</span>
                                                    <div className="badge">7</div>
                                                </a>
                                            </div>
                                            <div className="col-lg-2 mb-lg-3 px-8">
                                                <a href="/sold" className="single_megamenu">
                                                    <img src="https://www.my-equipment.com/newsite/images/megamenu/equipment/forklift.png" alt="Forklift" />
                                                    <span>Forklift</span>
                                                    <div className="badge">1</div>
                                                </a>
                                            </div>
                                            <div className="col-lg-2 mb-lg-3 px-8">
                                                <a href="/sold" className="single_megamenu">
                                                    <img src="https://www.my-equipment.com/newsite/images/megamenu/equipment/vibratory-rollers.png" alt="Vibratory Rollers" />
                                                    <span>Vibratory Rollers</span>
                                                    <div className="badge">20</div>
                                                </a>
                                            </div>
                                            <div className="col-lg-2 mb-lg-3 px-8">
                                                <a href="/sold" className="single_megamenu">
                                                    <img src="https://www.my-equipment.com/newsite/images/megamenu/equipment/cranes.png" alt="Cranes" />
                                                    <span>Cranes</span>
                                                    <div className="badge">5</div>
                                                </a>
                                            </div>
                                            <div className="col-lg-2 mb-lg-3 px-8">
                                                <a href="/sold" className="single_megamenu">
                                                    <img src="https://www.my-equipment.com/newsite/images/megamenu/equipment/compactor.png" alt="Compactor" />
                                                    <span>Compactor</span>
                                                    <div className="badge">2</div>
                                                </a>
                                            </div>
                                            <div className="col-lg-2 mb-lg-3 px-8">
                                                <a href="/sold" className="single_megamenu">
                                                    <img src="https://www.my-equipment.com/newsite/images/megamenu/equipment/asphalt-pavers.png" alt="Asphalt Pavers" />
                                                    <span>Asphalt Pavers</span>
                                                    <div className="badge">1</div>
                                                </a>
                                            </div>
                                            <div className="col-lg-2 mb-lg-3 px-8">
                                                <a href="/sold" className="single_megamenu">
                                                    <img src="https://www.my-equipment.com/newsite/images/megamenu/equipment/parts-attachments.png" alt="Parts & Attachments" />
                                                    <span>Parts &amp; Attachments</span>
                                                    <div className="badge">25</div>
                                                </a>
                                            </div> */}
                    </div>
                  </div>
                </div>
              </li>
            </ul>
          </div>
        </div>
      </nav>
      {/* <nav className="navbar bg-body-tertiary ">
        <div className="container-fluid">
          <a className="navbar-brand" href="#">
            Offcanvas navbar
          </a>
          <button
            className="navbar-toggler"
            type="button"
            data-bs-toggle="offcanvas"
            data-bs-target="#offcanvasNavbar"
            aria-controls="offcanvasNavbar"
            aria-label="Toggle navigation"
          >
            <span className="navbar-toggler-icon" />
          </button>
          <div
            className="offcanvas offcanvas-end"
            tabIndex={-1}
            id="offcanvasNavbar"
            aria-labelledby="offcanvasNavbarLabel"
          >
            <div className="offcanvas-header">
              <h5 className="offcanvas-title" id="offcanvasNavbarLabel">
                Offcanvas
              </h5>
              <button
                type="button"
                className="btn-close"
                data-bs-dismiss="offcanvas"
                aria-label="Close"
              />
            </div>
            <div className="offcanvas-body">
              <ul className="navbar-nav justify-content-end flex-grow-1 pe-3">
                <li className="nav-item">
                  <a className="nav-link active" aria-current="page" href="#">
                    Home
                  </a>
                </li>
                <li className="nav-item">
                  <a className="nav-link" href="#">
                    Link
                  </a>
                </li>
                <li className="nav-item dropdown">
                  <a
                    className="nav-link dropdown-toggle"
                    href="#"
                    role="button"
                    data-bs-toggle="dropdown"
                    aria-expanded="false"
                  >
                    Dropdown
                  </a>
                  <ul className="dropdown-menu">
                    <li>
                      <a className="dropdown-item" href="#">
                        Action
                      </a>
                    </li>
                    <li>
                      <a className="dropdown-item" href="#">
                        Another action
                      </a>
                    </li>
                    <li>
                      <hr className="dropdown-divider" />
                    </li>
                    <li>
                      <a className="dropdown-item" href="#">
                        Something else here
                      </a>
                    </li>
                  </ul>
                </li>
              </ul>
              <form className="d-flex mt-3" role="search">
                <input
                  className="form-control me-2"
                  type="search"
                  placeholder="Search"
                  aria-label="Search"
                />
                <button className="btn btn-outline-success" type="submit">
                  Search
                </button>
              </form>
            </div>
          </div>
        </div>
      </nav> */}
    </header>
  );
};

export default HeaderWithoutBg;
